// Sales Item
export class SalesItem {
  public id: number = 0
  public returnId: number = 0
  public title: string = ''
  public sku: string = ''
  public productId: number = 0
  public variantId: number = 0
  public shipMemoId: string = ''
  public variantTitle: string = ''
  public price: string = '0'
  public discountedPrice: string = '0'
  public tmpPrice: string = '0'
  public srcImage: string = ''
  public shipStatus: string = ''
  public quantity: number = 0
  public currentShopifyQuantity: number = 0
  public omsQuantity: number = 0
  public quantityInventory: number = 0
  public initialQuantity: number = 0
  public nonFulfillableQuantity: number = 0
  public refundableQuantity: number = 0
  public returnQuantity: number = 0
  public isGiftCard: boolean = false
  public shipPartial: boolean = false
  public taxRates: number[] = []
  public customAttributes: any[] = []

  get getId(): number {
    return this.id
  }

  set setId(newId: number) {
    this.id = newId
  }

  get getReturnId(): number {
    return this.returnId
  }

  set setReturnId(newReturnId: number) {
    this.returnId = newReturnId
  }

  get getTitle(): string {
    return this.title
  }

  set setTitle(newTitle: string) {
    this.title = newTitle
  }

  get getSku(): string {
    return this.sku
  }

  set setSku(newSku: string) {
    this.sku = newSku
  }

  get getProductId(): number {
    return this.productId
  }

  set setProductId(newProductId: number) {
    this.productId = newProductId
  }

  get getVariantId(): number {
    return this.variantId
  }

  set setVariantId(newVariantId: number) {
    this.variantId = newVariantId
  }

  set setShipMemoId(newShipMemoId: string) {
    this.shipMemoId = newShipMemoId
  }

  get getShipMemoId(): string {
    return this.shipMemoId
  }

  set setVariantTitle(newVariantTitle: string) {
    this.variantTitle = newVariantTitle
  }

  get getVariantTitle(): string {
    return this.variantTitle
  }

  get getPrice(): string {
    return this.price
  }

  set setPrice(newPrice: string) {
    this.price = newPrice
  }

  get getDiscountedPrice(): string {
    return this.discountedPrice
  }

  set setDiscountedPrice(newDiscountedPrice: string) {
    this.discountedPrice = newDiscountedPrice
  }

  set setTmpPrice(newPrice: string) {
    this.tmpPrice = newPrice
  }

  get getTmpPrice(): string {
    return this.tmpPrice
  }

  set setSrcImage(newSrcImage: string) {
    this.srcImage = newSrcImage
  }

  get getSrcImage(): string {
    return this.srcImage
  }

  get getQuantity(): number {
    return this.quantity
  }

  set setQuantity(newQuantity: number) {
    this.quantity = newQuantity
  }

  get getNewQuantityInventory(): number {
    return this.quantityInventory
  }

  set setNewQuantityInventory(newQuantityInventory: number) {
    this.quantityInventory = newQuantityInventory
  }

  get getInitialQuantity(): number {
    return this.initialQuantity
  }

  set setInitialQuantity(newInitialQuantity: number) {
    this.initialQuantity = newInitialQuantity
  }

  get getOmsQuantity(): number {
    return this.omsQuantity
  }

  set setOmsQuantity(newOmsQuantity: number) {
    this.omsQuantity = newOmsQuantity
  }

  get getCurrentShopifyQuantity(): number {
    return this.currentShopifyQuantity
  }

  set setCurrentShopifyQuantity(newCurrentShopifyQuantity: number) {
    this.currentShopifyQuantity = newCurrentShopifyQuantity
  }

  get getNonFulfillableQuantity(): number {
    return this.nonFulfillableQuantity
  }

  set setNonFulfillableQuantity(newNonFulfillableQuantity: number) {
    this.nonFulfillableQuantity = newNonFulfillableQuantity
  }

  get getRefundableQuantity(): number {
    return this.refundableQuantity
  }

  set setRefundableQuantity(newRefundableQuantity: number) {
    this.refundableQuantity = newRefundableQuantity
  }

  get getReturnQuantity(): number {
    return this.returnQuantity
  }

  set setReturnQuantity(newReturnQuantity: number) {
    this.returnQuantity = newReturnQuantity
  }

  get getIsGiftCard(): boolean {
    return this.isGiftCard
  }

  set setNewIsGiftCard(newIsGiftCard: boolean) {
    this.isGiftCard = newIsGiftCard
  }

  get getShipPartial(): boolean {
    return this.shipPartial
  }

  set setNewShipPartial(newShipPartial: boolean) {
    this.shipPartial = newShipPartial
  }

  get getTaxRates(): number[] {
    return this.taxRates
  }

  set setTaxRates(newTaxRates: number[]) {
    this.taxRates = newTaxRates
  }

  get getCustomAttributes(): any[] {
    return this.customAttributes
  }

  set setCustomAttributes(newCustomAttributes: any[]) {
    this.customAttributes = newCustomAttributes
  }
}
